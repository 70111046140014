<template>
  <div class="bg">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-5 col-lg-4 mx-auto mt-10 box">
          <div class="login-form mt-3 mt-md-0 mx-auto col-md-12 col-lg-10">
            <div style="text-align: center">
              <img
                style="margin-bottom: 50px; margin-top: 50px"
                src="../../img/logoTeros.png"
              />
            </div>

            <template v-if="!sended">
              <kinput
                type="text"
                key="org1"
                rules="required"
                v-model="usuario"
                placeholder="Informe o seu usuário"
              >
                Usuário
              </kinput>

              <template v-if="usuario">
                <p class="aviso-senha">Uma vez que a solicitação for enviada, sua senha antiga é invalidada.</p>

                <p class="aviso-senha">Mesmo que você relembre ela, não poderá mais utiliza-la para acessar a plataforma Teros.</p>
              </template>

              <div class="mx-auto">
                <div style="text-align: center">
                  <button
                    @click="doReset()"
                    class="btn btn-galapos"
                    type="submit"
                    :disabled="!usuario"
                  >
                    <span>Redefinir senha</span>
                    <v-icon small color="#FFF">mdi-chevron-right</v-icon>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <v-sheet rounded class="p-3 pb-1">
                <p>Solicitação aceita. Por favor, utilize o link enviado para o seu e-mail para criar uma nova senha.</p>
                <p>O link possui validade de <b>15 minutos</b>.</p>
                <p>Caso não identifique o recebimento do e-mail, por favor, entre em contato com a Galapos.</p>
              </v-sheet>
            </template>

            <div style="text-align: center">
              <img style="margin-top: 150px" src="../../img/logoGalapos.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    const { usuario } = this.$route.query;

    if (usuario) {
      this.usuario = usuario;
    }
  },
  components: {
    kinput: () => import("@/components/kInput.vue"),
  },
  computed: {
    resourceReset: function () {
      return this.apiResource(`/v1/recuperarSenha?usuario=${this.usuario}`);
    },
    fields: function () {
      return {
        email: {
          key: "usuario",
          name: "Usuário",
          type: this.$fieldTypes.TEXT,
        },
      };
    },
  },
  data: function () {
    return {
      sended: false,
      isErrorMessage: false,
      usuario: "",
    };
  },
  methods: {
    doReset: function () {
      if (this.usuario) {
        this.isErrorMessage = false;
        this.resourceReset.get()
          .then(() => {
            this.sended = true;
          })
          .catch((e) => {
            this.isErrorMessage = e.response.status;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  background: url("../../img/login.jpg") center;
  background-size: cover;
  height: 100vh;
}

.box {
  background: url("../../img/circulos.png") no-repeat;
  background-size: 100%;
  width: 625px;
}

.btn-galapos {
  background: #7dbacc;
  box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
  border-radius: 8px;
  color: white;
}

.aviso-senha {
  color: white;
}

.link-primary {
  color: #007bff !important;
}
</style>
